import React, { useContext, useEffect, useState } from "react"
import { LoadingContext } from "../../components/context/LoadingContext"
import angular from "./../../images/home/Experts/Angular.png"
import appium from "./../../images/home/Experts/Appium.png"
import aws from "./../../images/home/Experts/AWS.png"
import azureCloud from "./../../images/home/Experts/Azure cloud.png"
import azure from "./../../images/home/Experts/Azure.png"
import css from "./../../images/home/Experts/Css.png"
import firebase from "./../../images/home/Experts/Firebase.png"
import git from "./../../images/home/Experts/Git.png"
import gitlab from "./../../images/home/Experts/Gitlab.png"
import google from "./../../images/home/Experts/Google.png"
import heroku from "./../../images/home/Experts/Heroku.png"
import html from "./../../images/home/Experts/Html.png"
import ibmAs from "./../../images/home/Experts/Ibm as.png"
import ibmBlue from "./../../images/home/Experts/Ibm blue.png"
import ibm from "./../../images/home/Experts/Ibm.png"
import ionic from "./../../images/home/Experts/Ionic.png"
import java from "./../../images/home/Experts/Java.png"
import js from "./../../images/home/Experts/Javascript.png"
import jenkins from "./../../images/home/Experts/Jenkins.png"
import jmeter from "./../../images/home/Experts/Jmeter.png"
import junit from "./../../images/home/Experts/Junit.png"
import kotlin from "./../../images/home/Experts/Kotlin.png"
import mongo from "./../../images/home/Experts/Mongo.png"
import mysql from "./../../images/home/Experts/Mysql.png"
import net from "./../../images/home/Experts/Net.png"
import node from "./../../images/home/Experts/Node.png"
import nunit from "./../../images/home/Experts/Nunit.png"
import oracle from "./../../images/home/Experts/Oracle.png"
import php from "./../../images/home/Experts/Php.png"
import postgre from "./../../images/home/Experts/Postgre.png"
import python from "./../../images/home/Experts/Python.png"
import reactNative from "./../../images/home/Experts/React native.png"
import reactRed from "./../../images/home/Experts/React redux.png"
import redis from "./../../images/home/Experts/Redis.png"
import ruby from "./../../images/home/Experts/Ruby.png"
import selenium from "./../../images/home/Experts/Selenium.png"
import sqlServer from "./../../images/home/Experts/SQLServer.png"
import svn from "./../../images/home/Experts/Svn.png"
import swift from "./../../images/home/Experts/Swift.png"
import tfs from "./../../images/home/Experts/Tfs.png"
import visualB from "./../../images/home/Experts/Visual basic.png"
import VUE from "./../../images/home/Experts/Vue.png"
import xamarin from "./../../images/home/Experts/Xamarin.png"
import zendesk from "./../../images/home/Experts/Zendesk.png"
import arrowLeft from "./../../images/home/arrowLeft.jpg"
import arrowRigth from "./../../images/home/arrowRigth.jpg"
import i18n from "i18next"

const TechnologyStack = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  const titleSlider = [
    "Web",
    "Databases",
    "Programming Languages",
    "Mobile / IOS / Android",
    "Cloud / IaaS / Paas / SaaS",
    "Testing",
    "Continuous Integration",
    "Version Control",
  ]

  const elementsSlider = [
    [
      {
        image: js,
        texto: "1",
      },
      {
        image: html,
        texto: "1",
      },
      {
        image: css,
        texto: "1",
      },
      {
        image: angular,
        texto: "1",
      },
      {
        image: VUE,
        texto: "2",
      },
      {
        image: reactRed,
        texto: "2",
      },
      {
        image: ionic,
        texto: "2",
      },
    ],
    [
      {
        image: sqlServer,
        texto: "1",
      },
      {
        image: oracle,
        texto: "1",
      },
      {
        image: mongo,
        texto: "1",
      },
      {
        image: mysql,
        texto: "1",
      },
      {
        image: ibm,
        texto: "2",
      },
      {
        image: postgre,
        texto: "2",
      },
      {
        image: firebase,
        texto: "2",
      },
      {
        image: redis,
        texto: "2",
      },
    ],
    [
      {
        image: net,
        texto: "1",
      },
      {
        image: node,
        texto: "1",
      },
      {
        image: php,
        texto: "1",
      },
      {
        image: java,
        texto: "1",
      },
      {
        image: ibmAs,
        texto: "2",
      },
      {
        image: python,
        texto: "2",
      },
      {
        image: visualB,
        texto: "2",
      },
      {
        image: ruby,
        texto: "2",
      },
    ],
    [
      {
        image: java,
        texto: "1",
      },
      {
        image: swift,
        texto: "1",
      },
      {
        image: kotlin,
        texto: "1",
      },
      {
        image: xamarin,
        texto: "1",
      },
      {
        image: reactNative,
        texto: "2",
      },
      {
        image: ionic,
        texto: "2",
      },
    ],
    [
      {
        image: azureCloud,
        texto: "1",
      },
      {
        image: google,
        texto: "1",
      },
      {
        image: ibmBlue,
        texto: "1",
      },
      {
        image: heroku,
        texto: "1",
      },
      {
        image: firebase,
        texto: "2",
      },
      {
        image: aws,
        texto: "2",
      },
    ],
    [
      {
        image: selenium,
        texto: "1",
      },
      {
        image: appium,
        texto: "1",
      },
      {
        image: nunit,
        texto: "1",
      },
      {
        image: junit,
        texto: "1",
      },
      {
        image: jmeter,
        texto: "2",
      },
    ],
    [
      {
        image: gitlab,
        texto: "1",
      },
      {
        image: azure,
        texto: "1",
      },
      {
        image: jenkins,
        texto: "1",
      },
      {
        image: zendesk,
        texto: "1",
      },
    ],
    [
      {
        image: git,
        texto: "1",
      },
      {
        image: tfs,
        texto: "1",
      },
      {
        image: svn,
        texto: "1",
      },
    ],
  ]

  const [index, setIndex] = useState(0)
  const [imagesArr, setImagesArr] = useState(elementsSlider[index])
  const [titleArr, setTitleArr] = useState(titleSlider[index])

  const handleLeftArrowClick = () => {
    const i = index - 1
    if (typeof elementsSlider[i] === "undefined") {
      setIndex(elementsSlider.length - 1)
    } else {
      setIndex(i)
    }
  }

  const handleRightArrowClick = () => {
    const i = index + 1
    if (typeof elementsSlider[i] === "undefined") {
      setIndex(0)
    } else {
      setIndex(i)
    }
  }

  useEffect(() => {
    setImagesArr(elementsSlider[index])
    setTitleArr(titleSlider[index])
  }, [index])

  return (
    <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card pyWork">
      <div className="flip-card-inner2 desktop:flex desktop:ml-16 mobile:ml-4 desktop:mt-24 mobile:mt-8">
        <div>
          <p className="subTitleOurServices">
            {i18n.t("TechnologyStack.title")}
          </p>
          <p className="titleOurServices ">
            {i18n.t("TechnologyStack.subtitle")}
          </p>
        </div>
        <>
          {windowWidth >= 1025 ? (
            <>
              <div className="desktop:flex desktop:pr-12 ml-auto desktop:mr-40 bigdesktop:mr-64">
                <div className="desktop:flex ml-auto desktop:mr-12 mobile:mt-8">
                  <button onClick={handleLeftArrowClick}>
                    <img
                      src={arrowLeft}
                      alt="Imagen PopUp"
                      className="mr-8 mt-1"
                    />
                  </button>
                  <button onClick={handleRightArrowClick}>
                    <img src={arrowRigth} alt="Imagen PopUp" className="" />
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      </div>
      <>
        
        {windowWidth >= 1025 ? (
          <>
            {windowWidth >= 1025 && windowWidth <= 1700 ? (
              <>
                
                <p className="titleStandarAgile titleSectionStack">
                  {titleArr}
                </p>
                <div className="flex targetStacks mt-12 justifyStyle">
                  {imagesArr.slice(0, 4).map((item, i) => {
                    return (
                      <div className="contentTargetExperts ">
                        <div className="">
                          <img
                            src={item.image}
                            alt="...."
                            className="imgCardExpert"
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="flex targetStacks mt-12 ">
                  {imagesArr.slice(4, 8).map((item, i) => {
                    return (
                      <div className="contentTargetExperts ">
                        <div className="">
                          <img
                            src={item.image}
                            alt="...."
                            className={
                              item.texto == "2"
                                ? "imgCardExpertIonic"
                                : "imgCardExpert"
                            }
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </>
            ) : (
              <>
                
                <p className="titleStandarAgile titleSectionStack2">
                  {titleArr}
                </p>
                <div className="flex targetStacks mt-12 justifyStyle">
                  {imagesArr.slice(0, 4).map((item, i) => {
                    return (
                      <div className="contentTargetExperts ">
                        <div className="">
                          <img
                            src={item.image}
                            alt="...."
                            className="imgCardExpert"
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="flex  mt-12 marginTargetResponsive">
                  {imagesArr.slice(4, 8).map((item, i) => {
                    return (
                      <div className="contentTargetStackResponsive ">
                        <div className="">
                          <img
                            src={item.image}
                            alt="...."
                            className={
                              item.texto == "2"
                                ? "imgCardExpertIonic"
                                : "imgCardExpert"
                            }
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className=" targetExpertsMobile mt-12 ">
              <p className="titleStandarAgile">{titleArr}</p>
              {imagesArr.map((item, i) => {
                return (
                  <div className="flex mb-8 m-auto">
                    <div className="contentTargetStackMobile ">
                      <div className="">
                        <img
                          src={item.image}
                          alt="...."
                          className="imgCardExpert "
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className="desktop:flex desktop:pr-12 ml-auto desktop:mr-40 bigdesktop:mr-64">
                <div className="desktop:flex ml-auto desktop:mr-12 mobile:mt-8">
                  <button onClick={handleLeftArrowClick}>
                    <img src={arrowLeft} alt="Imagen PopUp" className="mr-8" />
                  </button>
                  <button onClick={handleRightArrowClick}>
                    <img src={arrowRigth} alt="Imagen PopUp" className="my-1" />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  )
}

export default TechnologyStack
