import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../../context/LoadingContext"
import image1 from "../../../images/agileTeam/Image1TEAM.png"
import image2 from "../../../images/agileTeam/Image2TEAM.png"
import check from "../../../images/products/check.png"
import { Link } from "gatsby"

const WhatIsAgileTeam = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  const imagesArr = [
    {
      texto: i18n.t("WhatIsAgileTeam.check1"),
    },
    {
      texto: i18n.t("WhatIsAgileTeam.check2"),
    },
    {
      texto: i18n.t("WhatIsAgileTeam.check3"),
    },
  ]

  return (
    <div className={`desktop:p-20 p-6`}>
      {windowWidth >= 1025 ? (
        <div className="flex w-full contentApply pt-16">
          <div className="  mt-10 mx-12 ">
            <div className="">
              <img src={image1} alt="...." className="ml-auto" />
            </div>

            <div className=" imgExsisAcademy">
              <img src={image2} alt="...." className="" />
            </div>
          </div>
          <div className="mt-24 mx-12 bigdesktop:w-1/2 bigdesktop:pl-20">
            <div className="eventRightTimeLineMobile">
              <p className="titleInfoExsisAcademy">
                {i18n.t("WhatIsAgileTeam.title")}
              </p>
            </div>

            <div className="mt-8">
              {imagesArr.map((item, i) => {
                return (
                  <div className="flex ">
                    <div className="flex mb-4">
                      <button className="checkWhatIsAgile">
                        <img src={check} alt="...." className="m-auto" />
                      </button>
                      <p className="textCheckWhatis bigdesktop:w-full">{item.texto}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            <Link to={"/contact-us"}>
              <button className="buttonLetsTalk flex justify-start items-center h-11 w-48">
                <span className="mx-auto">
                  {i18n.t("AboutThisProduct.button1")}
                </span>
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-10 divInfoExsisAcademyBootcampsMobile w-full">
            <div className=" ">
              <img src={image1} alt="...." className="" />
            </div>

            <div className="mt-4">
              <img src={image2} alt="...." className="" />
            </div>
          </div>
          <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-8">
            <div className="ml-4">
              <div className="eventRightTimeLineMobile">
                <p className="titleInfoExsisAcademy mb-8">
                  {i18n.t("WhatIsAgileTeam.title")}
                </p>
              </div>
              {imagesArr.map((item, i) => {
                return (
                  <div className="flex mt-4 ">
                    <div className=" flex">
                      <button className="checkWhatIsAgile">
                        <img src={check} alt="...." className="m-auto" />
                      </button>
                      <p className="textCheckWhatisMobile">{item.texto}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="flex items-center justify-center">
              <Link to={"/contact-us"}>
                <button className="buttonLetsTalk flex justify-center items-center mt-8 h-11 w-72">
                  <span className="mx-auto">
                    {i18n.t("AboutThisProduct.button1")}
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default WhatIsAgileTeam
