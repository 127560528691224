import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../../context/LoadingContext"
import image1 from "../../../images/products/imageTransformation.png"
import check from "../../../images/products/check.png"

const HowDoWeDoIt = ({ div }) => {
  const { windowWidth } = useContext(LoadingContext)

  const imagesArr = [
    "CodePipeline",
    "CodeCommit",
    "CodeBuild",
    "Amazon ECS",
    "Amazon EC2",
    "Amazon ECR",
  ]
  const imagesArr2 = [
    "AppSync",
    "Amazon S3",
    "Lambda",
    "Amazon Cognito",
    "Amazon Route 53",
  ]
  const imagesArr3 = [
    "GIT",
    "Azure Boards",
    "Visual Studio",
    "Visual Studio Code",
  ]

  return (
    <div className={`py-6  ${div}`}>
      {windowWidth >= 1025 ? (
        <>
          
          {windowWidth >= 1025 && windowWidth <= 1700 ? (
            <div className="flex mt-20 ml-16 contentApply mr-12 mb-12">
              <div className="">
                <div className="eventRightTimeLineMobile">
                  <p className="titleOurServices">
                    {i18n.t("HowDoWeDoIt.title")}
                  </p>
                </div>

                <p className="textExclusiveProducts">
                  {i18n.t("HowDoWeDoIt.text")}
                </p>
              </div>
              <div>
                <p className="titleOurServices ml-10">
                  {i18n.t("HowDoWeDoIt.subtitle")}
                </p>
                <div className="flex  mt-4 ml-12 ">
                  <div className="">
                    <p className="titleHowDo ">AWS</p>
                    {imagesArr.map((item, i) => {
                      return (
                        <div className="flex ml-2">
                          <div className="flex">
                            <div className="checkTool mt-3"></div>
                            <p className="textHowDo">{item}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="fieldTool">
                    {imagesArr2.map((item, i) => {
                      return (
                        <div className="flex ml-2">
                          <div className="flex">
                            <button className="checkTool mt-3"></button>
                            <p className="textHowDo">{item}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="ml-8">
                    <p className="titleHowDo">DevOps</p>
                    {imagesArr3.map((item, i) => {
                      return (
                        <div className="flex ml-2">
                          <div
                            className={
                              item == "Visual Studio Code"
                                ? "flex w-48"
                                : "flex "
                            }
                          >
                            <button className="checkTool mt-3"></button>
                            <p className="textHowDo">{item}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex mt-20 ml-16 mr-12 mb-12 bigdesktop:mr-48 bigdesktop:ml-48 contentApply">
              <div className="mt-4">
                <div className="eventRightTimeLineMobile">
                  <p className="titleOurServices">
                    {i18n.t("HowDoWeDoIt.title")}
                  </p>
                </div>

                <p className="textExclusiveProducts">
                  {i18n.t("HowDoWeDoIt.text")}
                </p>
              </div>
              <div className="">
                <p className="titleOurServices ml-10">{i18n.t("HowDoWeDoIt.subtitle")}</p>
                <div className="flex  mt-4 ml-12 ">
                  <div className="">
                    <p className="titleHowDo ">AWS</p>
                    {imagesArr.map((item, i) => {
                      return (
                        <div className="flex ml-2">
                          <div className="flex">
                            <div className="checkTool mt-3"></div>
                            <p className="textHowDo">{item}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="fieldTool">
                    {imagesArr2.map((item, i) => {
                      return (
                        <div className="flex ml-2">
                          <div className="flex">
                            <button className="checkTool mt-3"></button>
                            <p className="textHowDo">{item}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="ml-8">
                    <p className="titleHowDo">DevOps</p>
                    {imagesArr3.map((item, i) => {
                      return (
                        <div className="flex ml-2">
                          <div
                            className={
                              item == "Visual Studio Code"
                                ? "flex w-48"
                                : "flex "
                            }
                          >
                            <button className="checkTool mt-3"></button>
                            <p className="textHowDo">{item}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20 mb-20 ml-4">
          <div className="ml-4 mr-8">
            <div className="eventRightTimeLineMobile">
              <p className="titleOurServices">{i18n.t("HowDoWeDoIt.title")}</p>
            </div>

            <p className="text_our_experienceMobile mt-4">
              {i18n.t("HowDoWeDoIt.text")}
            </p>

            <div className="mt-10">
              <p className="titleOurServices">
                {i18n.t("HowDoWeDoIt.subtitle")}
              </p>
              <p className="titleHowDo mt-8">AWS</p>
              {imagesArr.map((item, i) => {
                return (
                  <div className="flex mt-4 ">
                    <div className=" flex ml-8">
                      <button className="checkTool"></button>
                      <p className="my-auto">{item}</p>
                    </div>
                  </div>
                )
              })}
              {imagesArr2.map((item, i) => {
                return (
                  <div className="flex mt-4 ">
                    <div className=" flex ml-8">
                      <button className="checkTool"></button>
                      <p className="my-auto">{item}</p>
                    </div>
                  </div>
                )
              })}
              <p className="titleHowDo mt-8">DevOps</p>
              {imagesArr3.map((item, i) => {
                return (
                  <div className="flex mt-4 ">
                    <div className=" flex ml-8">
                      <button className="checkTool"></button>
                      <p className="my-auto">{item}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default HowDoWeDoIt
