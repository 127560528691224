import React, { useContext } from "react"
import i18n from "i18next"
import { LoadingContext } from "../../context/LoadingContext"
import { Link } from "gatsby"

const LetsTalkAgileTeam = ({}) => {
  const { windowWidth } = useContext(LoadingContext)

  return (
    <>
      {windowWidth >= 1025 ? (
        <>
          <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 flip-card bannerAgileTeam">
            <>
              
              {windowWidth >= 1025 && windowWidth <= 1700 ? (
                <div className="flip-card-inner2 contentBannerAgileTeams  mt-24 mb-24">
                  <div className="grid">
                    <p className="titleBannerAgileTeam">
                      {i18n.t("LetsTalkAgileTeam.text")}
                    </p>
                    <Link to={"/contact-us"}>
                      <button className="buttonLetsTalk flex justify-start items-center h-11 w-48">
                        <span className="mx-auto">
                          {i18n.t("AboutThisProduct.button1")}
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="flip-card-inner2 contentBannerAgileTeamsResponsive  mt-24 mb-24">
                  <div className="grid">
                    <p className="titleBannerAgileTeam">
                      {i18n.t("LetsTalkAgileTeam.text")}
                    </p>
                    <Link to={"/contact-us"}>
                      <button className="buttonLetsTalk flex justify-start items-center h-11 w-48">
                        <span className="mx-auto">
                          {i18n.t("AboutThisProduct.button1")}
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </>
          </div>
        </>
      ) : (
        <div className="mobile:w-full desktop:pl-8 desktop:pr-4 mobile:pl-4 mobile:pr-4 pb-4 pt-4 bannerAgileTeamMobile">
          <div className=" mx-4 mt-24 mb-24">
            <div className="grid">
              <p className="titleBannerAgileTeamMobile">
                {i18n.t("LetsTalkAgileTeam.text")}
              </p>
              <Link to={"/contact-us"}>
                <button className="buttonLetsTalk flex justify-start items-center h-11 w-full">
                  <span className="mx-auto">
                    {i18n.t("AboutThisProduct.button1")}
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LetsTalkAgileTeam
