import React, { Fragment, useEffect, useState, useRef } from "react"
import i18n from "i18next"

import agile from "../images/agileTeam/AgileIconBG.png"
import leader from "../images/agileTeam/TechnicalLeaderBG.png"
import designer from "../images/agileTeam/UIDesignerBG.png"
import frontend from "../images/agileTeam/FrontendDeveloperBG.png"
import tester from "../images/agileTeam/AnalystTesterBG.png"
import backend from "../images/agileTeam/BackendDeveloperBG.png"

import benefits1 from "../images/agileTeam/BetterdeliveryBG.png"
import benefits2 from "../images/agileTeam/MotivatedteamBG.png"
import benefits3 from "../images/agileTeam/ProjectProtectionBG.png"

// Layout
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next"

import columnImageRight from "./../images/agileTeam/AgileTeamPlaceholder.png"
import TechnologyStack from "../components/serviceDev&Cloud/TechnologyStack"
import BannerAgileTeam from "../components/Products/AgileTeam/BannerAgileTeam"
import LetsTalkAgileTeam from "../components/Products/AgileTeam/LetsTalkAgileTeam"
import WhatIsAgileTeam from "../components/Products/AgileTeam/WhatIsAgileTeam"
import TargetStandard from "../components/Products/AgileTeam/TargetStandard"
import HowDoWeDoIt from "../components/Products/AgileTeam/HowDoWeDoIt"
import WhatBenefits from "../components/Products/AgileTeam/WhatBenefits"

const AgileTeam = () => {
  const [descriptionTitleBanner, setDescriptionTitleBanner] = useState([])
  const myRefForm = useRef(null)
  const executeScroll = () => myRefForm.current.scrollIntoView()

  const targetCountArr = [
    {
      image: agile,
      texto: i18n.t("WhatIsAgileTeam.card1"),
    },
    {
      image: leader,
      texto: i18n.t("WhatIsAgileTeam.card2"),
    },
    {
      image: designer,
      texto: i18n.t("WhatIsAgileTeam.card3"),
    },
    {
      image: frontend,
      texto: i18n.t("WhatIsAgileTeam.card4"),
    },
    {
      image: tester,
      texto: i18n.t("WhatIsAgileTeam.card5"),
    },
    {
      image: backend,
      texto: i18n.t("WhatIsAgileTeam.card6"),
    },
  ]

  const targetBenefits = [
    {
      image: benefits1,
      texto: i18n.t("WhatBenefits.card1"),
    },
    {
      image: benefits2,
      texto: i18n.t("WhatBenefits.card2"),
    },
    {
      image: benefits3,
      texto: i18n.t("WhatBenefits.card3"),
    },
  ]

  useEffect(() => {
    if (i18n.language === "en") {
      setTitleBanner("en")
    } else {
      setTitleBanner("es")
    }
  }, [i18n.language])

  const setTitleBanner = lng => {
    setDescriptionTitleBanner([
      <div className="flex flex-col txt-our-adn txt-our-adn-movil">
        <h3 className="inline-block desktop:w-10/12 desktop:text-left mobile:text-left mb-4 mobile:mx-2 desktop:mx-24">
          <br></br>
          <br></br>
          <br></br>
          {lng === "en" ? (
            <>
              <span className="textOurExperience2 flex">
                <p className="subtitleTextTalent">E</p>
                <p className="subtitleTextTalentX">x</p>
                <p className="subtitleTextTalent">ceptional products</p>
              </span>
              <span className="textTalentATS">
                <p className="colorTextExperience2">Agile</p>
                <p className="colorTextExperience2">Teams</p>
              </span>
            </>
          ) : (
            <>
              <span className="textOurExperience2 flex">
                <p className="subtitleTextTalent">Productos E</p>
                <p className="subtitleTextTalentX">x</p>
                <p className="subtitleTextTalent">cepcionales</p>
              </span>
              <span className="textTalentATS">
                <p className="colorTextExperience2">Equipos</p>
                <p className="colorTextExperience2">Ágiles</p>
              </span>
            </>
          )}
          <br></br>
          <br></br>
          <br></br>
        </h3>
      </div>,
    ])
  }

  return (
    <Layout>
      <SEO
        title={"Agile Team | Exsis Digital Angels"}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />
      <BannerAgileTeam
        bannerImageURL={columnImageRight}
        bannerImageContent={descriptionTitleBanner}
      ></BannerAgileTeam>

      <LetsTalkAgileTeam />
      <WhatIsAgileTeam />

      <TargetStandard arrayitems={targetCountArr} />
      <HowDoWeDoIt div="whyusdiv" />

      <WhatBenefits arrayitems={targetBenefits} />

      <TechnologyStack />
    </Layout>
  )
}

export default withNamespaces()(AgileTeam)
