import React, { useContext } from "react"
import i18n from "i18next"
import Slider from "react-slick"
import { LoadingContext } from "../../context/LoadingContext"

const WhatBenefits = ({ arrayitems }) => {
  const { windowWidth } = useContext(LoadingContext)

  const imgStyle = {
    maxWidth: "1.8rem",
    maxHeight: "3rem",
  }

  const imgStyle2 = {
    maxWidth: "6rem",
    maxHeight: "5.5rem",
  }

  const imgStylemovil = {
    maxWidth: "1.2rem",
    maxHeight: "3rem",
  }

  const settings = {
    dots: true,
    dotsClass: "slick-dots blue-dark-slide-dots hidden-desktop__dot",
    infinite: true,
    autoPlay: true,
    autoPlaySpeed: 3000,
    slidesToShow: 1,
    initialSlide: 0,
    speed: 500,
  }

  return (
    <div className={`desktop:p-20 p-6 `}>
      {windowWidth >= 1025 ? (
        <>
          <div className="">
            <div>
              <p className="subTitleOurServices">
                {i18n.t("WhatBenefits.title")}
              </p>
              <p className="titleOurServices titleBenefits2">
                {i18n.t("WhatBenefits.subtitle")}
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mt-4">
            <div>
              <p className="subTitleOurServices">
                {i18n.t("WhatBenefits.title")}
              </p>
              <p className="titleOurServices mb-10">
                {i18n.t("WhatBenefits.subtitle")}
              </p>
            </div>
          </div>
        </>
      )}

      <div className="desktop:flex desktop:flex-wrap contenfImgCoreSolution">
        {windowWidth >= 1025
          ? arrayitems.map((item, i) => {
              return (
                <div key={i.toString()} className=" mb-6">
                  <div className="contentDivBenefits">
                    <div className="flex justify-center mb-2 mt-4 ">
                      <img
                        src={item.image}
                        style={imgStyle2}
                        className="mr-1 mt-1"
                      />
                    </div>
                    <div className="flex flew-row text-left w-auto ">
                      <div className="w-full text-left">
                        <p className="pr-2 pl-2 textDivBenefits desktop:text-20">
                          {item.texto}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          : null}

        {windowWidth < 1025
          ? arrayitems.map((item, i) => {
              return (
                <div key={i.toString()} className="flex mb-6">
                  <div className="text-left w-auto contentDivTechnologyStack">
                    <div className="flex justify-center mb-2">
                      <img
                        src={item.image}
                        style={imgStyle2}
                        className="mr-1 mt-1"
                      />
                    </div>
                    <div className="w-full text-left">
                      <p className="pr-2 pl-2 textDivBenefits desktop:text-20 mobile:text-16">
                        {item.texto}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })
          : null}
      </div>
      <br />
    </div>
  )
}

export default WhatBenefits
