import React, { useContext } from "react"
import i18n from "i18next"
import { LoadingContext } from "../../context/LoadingContext"

const TargetStandard = ({ arrayitems }) => {
  const { windowWidth } = useContext(LoadingContext)

  const imgStyle2 = {
    maxWidth: "6rem",
    maxHeight: "5.5rem",
  }

  return (
    <>
      {windowWidth >= 1025 ? (
        <>
          <div className="p-20 w-full">
            <p className="titleStandarAgile">
              {i18n.t("WhatIsAgileTeam.subtitle")}:
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: windowWidth >= 1700 ? "576px" : "400px",
            }}
          >
            {arrayitems.slice(0, 3).map((item, i) => {
              return (
                <div key={i.toString()} className=" mb-6">
                  <div
                    style={{
                      width: windowWidth >= 1700 ? "280px" : "192px",
                      height: "192px",
                      backgroundColor: "white",
                      borderRadius: "12px",
                      boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.08)",
                      marginRight: "16px",
                      padding: "16px",
                    }}
                  >
                    <div className="flex justify-center mb-2 mt-4">
                      <img src={item.image} className="mr-1 mt-1" />
                    </div>
                    <div className="flex flew-row text-left w-auto ">
                      <div className="w-full text-left">
                        <p className="pr-2 pl-2 textTargetStandar ">
                          {item.texto}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: windowWidth >= 1700 ? "608px" : "432px",
            }}
          >
            {arrayitems.slice(3, 6).map((item, i) => {
              return (
                <div key={i.toString()} className=" mb-24">
                  <div
                    style={{
                      width: windowWidth >= 1700 ? "280px" : "192px",
                      height: "192px",
                      backgroundColor: "white",
                      borderRadius: "12px",
                      boxShadow: "0px 20px 40px 0px rgba(0, 0, 0, 0.08)",
                      marginRight: "16px",
                      padding: "16px",
                    }}
                  >
                    <div className="flex justify-center mb-2 mt-4">
                      <img src={item.image} className="mr-1 mt-1" />
                    </div>
                    <div className="flex flew-row text-left w-auto ">
                      <div className="w-full text-left">
                        <p className="pr-2 pl-2 textTargetStandar ">
                          {item.texto}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <div className="p-10">
          <p className="titleStandarAgile">
            {i18n.t("WhatIsAgileTeam.subtitle")}:
          </p>
          {arrayitems.map((item, i) => {
            return (
              <div key={i.toString()} className="flex mb-6">
                <div className="text-left w-auto contentDivCoreMobile">
                  <div className="flex justify-center m-2">
                    <img
                      src={item.image}
                      style={imgStyle2}
                      className="mr-1 mt-8"
                    />
                  </div>
                  <div className="w-full text-left">
                    <p className="pr-2 pl-2 textTargetStandar desktop:text-20 mobile:text-16">
                      {item.texto}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default TargetStandard
