import React, { Fragment, useState, useContext } from "react"
import { LoadingContext } from "../../context/LoadingContext"
import i18n from "../../i18n"

const BannerAgileTeam = ({ bannerImageURL, bannerImageContent }) => {
  const [dinamicHeight, setDinamicHeight] = useState(0)
  const [dinamicHeightColBanner, setDinamicHieghtColbanner] = useState(0)
  const { windowWidth } = useContext(LoadingContext)

  return (
    <Fragment>
      <div className="container-banner-main">
        <div className="shared-banner-container">
          {windowWidth >= 1025 ? (
            <div className="flex mobile:flex-col-reverse desktop:flex-row bannerPrincipal">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16"
              >
                {bannerImageContent}
              </div>
              <div className="shared-banner-imageHome mobile:ml-0">
                <div className="bannerAcademyContentStyle">
                  <img
                    src={bannerImageURL}
                    alt="Imagen PopUp"
                    className="imgBannerExperience"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex desktop:flex-row bannerPrincipalMobile">
              <div
                ref={el => {
                  if (el) {
                    const resizeObserver = new ResizeObserver(entries => {
                      setDinamicHieghtColbanner(entries[0].contentRect.height)
                    })
                    resizeObserver.observe(el)
                  }
                }}
                className="shared-banner-content-btn m-4 desktop:mt-4 mobile:mt-16"
              >
                {bannerImageContent}
              </div>
            </div>
          )}

          <div className="desktop:flex desktop:flex-wrap desktop:px-6 mt-20">
            {windowWidth >= 1025 ? (
              <div className=" w-full ml-8">
                <div className="contentWhyUs ">
                  <div className="eventRightTimeLineMobile ml-auto styleContentCurrently bigdesktop:w-3/5">
                    <p className="titleInfoExsisAcademy">
                      {i18n.t("BannerAgileTeam.title")}
                    </p>
                  </div>
                  {windowWidth >= 1025 ? (
                    <>
                      <p className="eventRightTimeLineMobile textInfoCurrently ml-auto bigdesktop:w-3/5 bigdesktop:mr-48">
                        {i18n.t("BannerAgileTeam.text")}
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <div className="w-full">
                <div className="contentWhyUs divInfoExsisAcademyMobile">
                  <div className="eventRightTimeLineMobile">
                    <p className="titleInfoExsisAcademy">
                      {i18n.t("BannerAgileTeam.title")}
                    </p>
                  </div>
                  {windowWidth >= 1025 ? (
                    <></>
                  ) : (
                    <>
                      <p className="textInfoExsisAcademy2Mobile mt-4">
                        {i18n.t("BannerAgileTeam.text")}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

BannerAgileTeam.defaultProps = {
  flag: true,
  clase: "shared-banner-footer banner-text-white mt-8",
  style: {},
}

export default BannerAgileTeam
